/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import AdminLayout from 'src/layouts/DashboardLayout';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminRoute from 'src/components/AdminRoute';
import UserGuard from 'src/components/UserGuard';


const routesConfig = [
  {
    exact: true,
    path: '/',
    component: lazy(() => import('src/views/pages/LandingPageView'))
  },
  {
    exact: true,
    path: '/stealth',
    component: () => <Redirect to="/stealth/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/stealth/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/admin/login',
    component: lazy(() => import('src/views/auth/AdminLoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
   {
    path: '/app',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        guard : UserGuard,
        layout : AdminLayout,
        path: '/app/admin/interface/home',
        component: lazy(() => import('src/views/Admin/AdminBoard'))
      },
      {
        exact: true,
        guard : UserGuard,
        layout : AdminLayout,
        path: '/app/admin/saveSearch/results',
        component: lazy(() => import('src/views/Admin/SearchResults'))
      },
      {
        exact: true,
        guard : UserGuard,
        layout : AdminLayout,
        path: '/app/admin/hiddenData/list',
        component: lazy(() => import('src/views/Admin/HiddenData'))
      },
      {
        exact: true,
        guard : UserGuard,
        layout : AdminLayout,
        path: '/app/admin/customers/list',
        component: lazy(() => import('src/views/Admin/Customers'))
      },
      {
        exact: true,
        guard : AdminRoute,
        path: '/app',
        component: () => <Redirect to="/app/solar-panels" />
      },
      {
        exact: true,
        path: '/app/solar-panels',
        component: lazy(() => import('src/views/listView'))
      },
      {
        exact: true,
        path: '/app/solar-panels/request-quote/:id',
        component: lazy(() => import('src/views/requestQuote'))
      },
      {
        exact: true,
        path: '/app/solar-panels/request-quote-inverter/:id',
        component: lazy(() => import('src/views/requestQuoteInverters'))
      },
      {
        exact: true,
        path: '/app/solar-panels/make-offer/:id',
        component: lazy(() => import('src/views/makeOffer'))
      },
      {
        exact: true,
        path: '/app/solar-panels/make-offer-inverter/:id',
        component: lazy(() => import('src/views/makeOfferInverter'))
      },
      {
        exact: true,
        path: '/app/solar-panels/request-product',
        component: lazy(() => import('src/views/requestProduct'))
      },
      {
        exact: true,
        path: '/app/solar-panels/product-offer',
        component: lazy(() => import('src/views/ProductOffer'))
      },
      {
        exact: true,
        path: '/app/solar-panels/get-spec/:id',
        component: lazy(() => import('src/views/getSpecs'))
      },
      {
        exact: true,
        path: "/app/modules/product/details/:id",
        component: lazy(() => import('src/views/ProductDetails'))
      },
      {
        exact: true,
        path: "/app/solar-panels/inverters/:brand/:type/:model/:id",
        component: lazy(() => import('src/views/ProductDetails'))
      },
      {
        exact: true,
        path: "/app/solar-panels/:brand/:type/:model/:wattage/:id",
        component: lazy(() => import('src/views/ProductDetails'))
      },

      
      {
        component: () => <Redirect to="/404" />
      }
    ]
    },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/pages/LandingPageView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
